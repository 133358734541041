<template>
  <div>
    <h2>Neutralization of a strong acid with strong base: Data + Calculations</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Data Table</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Table Caption"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Write a caption for the data table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Units</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
          </tr>
        </thead>
        <tr>
          <th style="text-align: left">Acid</th>
          <td>&nbsp;</td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${i}-${j}`" style="text-align: center">
              <stemble-latex :content="`$\\ce{${j % 2 ? 'HCl' : 'HNO3'}}$`" />
            </td>
          </template>
        </tr>
        <tr v-for="{label, key, units: rowUnits} in dataTableRows" :key="key">
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${key}-${i}-${j}`">
              <calculation-input
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                class="centered-input"
              />
            </td>
          </template>
        </tr>
      </v-simple-table>

      <!-- Data Upload -->
      <h3 class="pt-8 pb-3">Graph Upload</h3>

      <p class="mb-2">
        In this experiment, you are collecting temperature data as a function of time. Plot your
        temperature-time data (to complete your analysis you must plot the data for both trials, but
        we will only ask you to upload the graph for one of the two trials).
      </p>

      <p class="mb-2">
        Ensure that your graph has a proper title and figure heading and that you place the correct
        data on the correct axis. Label your axes and indicate units where appropriate. Refer to
        Figure 2.1 in the Graphs section in the Experimental protocol and draw the vertical line at
        time of mixing. Then extrapolate back to the time of mixing from your stabilized final
        temperature (it can be horizontal or inclined depending on your data).
      </p>

      <p class="mb-2">
        You have completed 2 trials for each of the 2 acids. Upload the graph of one of the two
        trials for EACH acid (2 graphs) using the link below. Your graphs should be clearly titled
        and in pdf format.
      </p>

      <p class="mb-5">
        <v-file-input v-model="attachments" multiple />
      </p>

      <!-- Calculations -->
      <h3 class="pt-4 pb-3">Calculations</h3>

      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Table Caption"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Write a caption for the calculations table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Units</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
          </tr>
        </thead>
        <tr
          v-for="{label, key, units: rowUnits, type: inputType} in calculationTableRows"
          :key="key"
        >
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${key}-${i}-${j}`">
              <v-textarea
                v-if="inputType === 'string'"
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                outlined
                solo
                rows="3"
              />
              <calculation-input
                v-else
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                class="centered-input"
              />
            </td>
          </template>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask6Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    const inputs = {};

    const dataTableRows = [
      {label: 'Volume of Base', key: 'volume_base', units: true},
      {label: 'Concentration of Base', key: 'concentration_base', units: true},
      {label: 'Mass of Calorimeter', key: 'mass_calorimeter', units: true},
      {label: 'Mass (Calorimeter + Base)', key: 'mass_calorimeter_and_base', units: true},
      {label: 'Initial Temperature of Base and Acid', key: 'initial_temp_base_acid', units: true},
      {label: 'Volume of Acid', key: 'volume_acid', units: true},
      {label: 'Concentration of Acid', key: 'concentration_acid', units: true},
      {label: 'Final Temperature of Mixture', key: 'final_temp_mixture', units: true},
      {
        label: 'Final Mass (Calorimeter + Contents)',
        key: 'mass_calorimeter_and_contents',
        units: true,
      },
    ];

    const calculationTableRows = [
      {label: '$m_{soln}$', key: 'mass_solution', units: true},
      {label: '$\\Delta_{rxn} T$', key: 'delta_temp_reaction', units: true},
      {label: '$c_{soln}$', key: 'specific_heat_solution', units: true},
      {label: '$n_{acid}$', key: 'moles_acid', units: true},
      {label: '$n_{base}$', key: 'moles_base', units: true},
      {label: '$n_{rxn}$', key: 'moles_rxn', units: true},
      {
        label: '$\\Delta_N H^\\omicron$ (Experimental)',
        key: 'enthalpy_neutralization',
        units: true,
      },
      {
        label: '$\\Delta_N H^\\omicron$ (Literature)',
        key: 'literature_enthalpy_neutralization',
        units: true,
      },
      {
        label: 'Reference for $\\Delta_N H^\\omicron$',
        key: 'reference_literature_enthalpy_neutralization',
        units: false,
        type: 'string',
      },
      {label: 'Percent Error', key: 'percent_error_enthalpy', units: true},
    ];

    for (const {key, units} of dataTableRows) {
      if (units) {
        inputs[`${key}_units`] = null;
      }
    }

    for (const {key, units} of calculationTableRows) {
      if (units) {
        inputs[`${key}_units`] = null;
      }
    }

    for (let trial = 1; trial <= 2; trial++) {
      for (const compound of ['hcl', 'hno3']) {
        for (const {key} of dataTableRows) {
          inputs[`trial${trial}_${compound}_${key}`] = null;
        }

        for (const {key} of calculationTableRows) {
          inputs[`trial${trial}_${compound}_${key}`] = null;
        }
      }
    }

    return {
      inputs: {
        data_table_caption: null,
        calculations_table_caption: null,
        ...inputs,
      },
      attachments: [],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'C', label: '$^{\\omicron}\\text{C}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {key: 'M', label: '$\\text{M}$'},
        {key: 'kJPerMol', label: '$\\text{kJ} \\cdot \\text{mol}^{-1}$'},
        {key: 'mol', label: '$\\text{mol}$'},
        {key: 'gPerMol', label: '$\\text{g} \\cdot \\text{mol}^{-1}$'},
        {
          key: 'JGC',
          label: '$\\text{J} \\cdot \\text{g}^{-1} \\cdot {^{\\omicron}\\text{C}}^{-1}$',
        },
        {
          key: 'percent',
          label: '$\\%$',
        },
      ],
      dataTableRows,
      calculationTableRows,
    };
  },
};
</script>
